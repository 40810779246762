import API from '@/shared/enums/api'
import axios from 'axios'

export default {
  namespaced: true,

  state: {
    apiState: API.INIT,
    groups: []
  },

  getters: {
    group: (state) => (name) => state.groups.filter((group) => group.name === name)
  },

  mutations: {
    setApiState(state, status) {
      state.apiState = status
    },
    setGroups(state, data) {
      state.groups = data
    },
    addGroup(state, data) {
      state.groups.push(data)
    },
    delGroup(state, data) {
      const index = state.groups.findIndex((group) => group.id === data.id)
      if (index !== -1) {
        state.groups.splice(index, 1)
      }
    },
    patchGroup(state, data) {
      const index = state.groups.findIndex((group) => group.id === data.id)
      if (index !== -1) {
        Object.assign(state.groups[index], data)
      }
    }
  },

  actions: {
    async fetchGroups({ commit }) {
      try {
        commit('setApiState', API.LOADING)
        var data = (await axios(process.env.VUE_APP_API_URL + 'super/v1/groups')).data
        commit('setGroups', data)
        commit('setApiState', API.LOADED)
      } catch (error) {
        console.log(error)
        commit('setApiState', API.ERROR)
      }
    },
    async addGroup({ commit, dispatch }, data) {
      try {
        commit('setApiState', API.LOADING)
        const response = await axios.post(process.env.VUE_APP_API_URL + 'super/v1/groups', data, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        if (response.status === 200) {
          dispatch('setSnackbar', { icon: 'mdi-check', text: `Success: ${response.data.name} added!`, color: 'success darken-2' }, { root: true })
          commit('addGroup', response.data)
        }
        commit('setApiState', API.LOADED)
      } catch (error) {
        console.log(error)
        dispatch('setSnackbar', { icon: 'mdi-alert', text: `Something went wrong adding this group.`, color: 'error darken-2' }, { root: true })
        commit('setApiState', API.ERROR)
      }
    },
    async delGroup({ commit, dispatch }, data) {
      try {
        commit('setApiState', API.LOADING)
        const response = await axios.delete(process.env.VUE_APP_API_URL + `super/v1/groups/${data.id}`)
        if (response.status === 200) {
          dispatch('setSnackbar', { icon: 'mdi-trash-can', text: `Success: ${data.name} deleted!`, color: 'red darken-2' }, { root: true })
          commit('delGroup', data)
        }
        commit('setApiState', API.LOADED)
      } catch (error) {
        console.log(error)
        dispatch('setSnackbar', { icon: 'mdi-alert', text: `Something went wrong removing this group.`, color: 'red darken-2' }, { root: true })
        commit('setApiState', API.ERROR)
      }
    },
    async patchGroup({ commit, dispatch }, data) {
      try {
        commit('setApiState', API.LOADING)
        const response = await axios.post(process.env.VUE_APP_API_URL + `super/v1/groups/${data.get('id')}`, data)
        if (response.status === 200) {
          dispatch('setSnackbar', { icon: 'mdi-check', text: `Success: ${response.data.name} updated!`, color: 'green darken-2' }, { root: true })
          commit('patchGroup', response.data)
        }
        commit('setApiState', API.LOADED)
      } catch (error) {
        console.log(error)
        dispatch('setSnackbar', { icon: 'mdi-alert', text: `Something went wrong updating this group.`, color: 'red darken-2' }, { root: true })
        commit('setApiState', API.ERROR)
      }
    }
  }
}
