import API from '@/shared/enums/api'
import axios from 'axios'

export default {
  namespaced: true,

  state: {
    apiState: API.INIT,
    stickers: []
  },

  getters: {
    stickerData: (state) => state.stickers
  },

  mutations: {
    setApiState(state, status) {
      state.apiState = status
    },
    setStickerData(state, data) {
      state.stickers = data
    },
    patchStickerData(state, data) {
      const index = state.stickers.findIndex((sticker) => sticker.id === data.id)
      if (index !== -1) {
        state.stickers[index].count = data.count
      }
    }
  },

  actions: {
    async fetchStickerData({ commit }) {
      try {
        commit('setApiState', API.LOADING)
        var data = (await axios(process.env.VUE_APP_API_URL + 'v1/stickers')).data
        commit('setStickerData', data)
        commit('setApiState', API.LOADED)
      } catch (error) {
        console.log(error)
        commit('setApiState', API.ERROR)
      }
    },
    async patchStickerData({ commit, dispatch }, data) {
      try {
        commit('setApiState', API.LOADING)
        const response = await axios.post(process.env.VUE_APP_API_URL + `v1/stickers/${data.get('id')}`, data)
        if (response.data.outOfStickers) {
          dispatch(
            'setSnackbar',
            {
              icon: 'mdi-alert',
              text: `Oh no! Looks like you're out of banana stickers for the day! Come back tomorrow and give some more stickers.`,
              color: 'red darken-2'
            },
            { root: true }
          )
        } else if (response.status === 200) {
          switch (data.get('vote')) {
            case 'UP':
              dispatch(
                'setSnackbar',
                {
                  icon: 'mdi-check',
                  text: `Success! You're awesome because you awarded ${response.data.owner} another banana sticker!`,
                  color: 'green darken-2'
                },
                { root: true }
              )
              break
            case 'DOWN':
              dispatch(
                'setSnackbar',
                {
                  icon: 'mdi-emoticon-sad-outline',
                  text: `Success! You're a monster and you stole a hard earned banana sticker from ${response.data.owner}!`,
                  color: 'red darken-2'
                },
                { root: true }
              )
              break
          }
          commit('patchStickerData', response.data)
        }
        commit('setApiState', API.LOADED)
      } catch (error) {
        dispatch('setSnackbar', { icon: 'mdi-alert', text: `Something went wrong.`, color: 'red darken-2' }, { root: true })
        commit('setApiState', API.ERROR)
      }
    }
  }
}
