import axios from 'axios'

export default {
  namespaced: true,

  state: {
    userData: null
  },

  getters: {
    user: (state) => state.userData
  },

  mutations: {
    setUserData(state, user) {
      state.userData = user
    }
  },

  actions: {
    getUserData({ commit }) {
      axios
        .get(process.env.VUE_APP_API_URL + 'auth/v1/user')
        .then((response) => {
          commit('setUserData', response.data)
          localStorage.setItem('userData', JSON.stringify(response.data))
        })
        .catch(() => {
          localStorage.removeItem('authToken')
          localStorage.removeItem('userData')
        })
    },

    sendLoginRequest({ commit }, data) {
      commit('setErrors', {}, { root: true })
      return axios.post(process.env.VUE_APP_API_URL + 'auth/v1/login', data).then((response) => {
        commit('setUserData', response.data.user)
        localStorage.setItem('authToken', response.data.token)
        localStorage.setItem('userData', JSON.stringify(response.data.user))
      })
    },

    sendRegisterRequest({ commit }, data) {
      commit('setErrors', {}, { root: true })
      return axios.post(process.env.VUE_APP_API_URL + 'auth/v1/register', data).then((response) => {
        commit('setUserData', response.data.user)
        localStorage.setItem('authToken', response.data.token)
        localStorage.setItem('userData', JSON.stringify(response.data.user))
      })
    },

    sendLogoutRequest({ commit }) {
      axios.post(process.env.VUE_APP_API_URL + 'auth/v1/logout').then(() => {
        commit('setUserData', null)
        localStorage.removeItem('authToken')
        localStorage.removeItem('userData')
      })
    },

    sendVerifyResendRequest() {
      return axios.get(process.env.VUE_APP_API_URL + 'auth/v1/email/resend')
    },

    sendVerifyRequest({ dispatch }, hash) {
      return axios.get(process.env.VUE_APP_API_URL + 'auth/v1/email/verify/' + hash).then(() => {
        dispatch('getUserData')
      })
    }
  }
}
