import API from '@/shared/enums/api'
import axios from 'axios'

export default {
  namespaced: true,

  state: {
    apiState: API.INIT,
    serviceTypes: [],
    vehicles: [],
    currentVehicle: '',
    fuelLogs: [],
    serviceLogs: []
  },

  getters: {
    vehicle: (state) => (name) => state.vehicles.filter((vehicle) => vehicle.name === name),
    vehicleFuelLogs: (state) => (name) => state.fuelLogs.filter((log) => log.name === name),
    vehicleServiceLogs: (state) => (name) => state.serviceLogs.filter((log) => log.name === name)
  },

  mutations: {
    setApiState(state, status) {
      state.apiState = status
    },
    setVehicles(state, data) {
      state.vehicles = data
    },
    setServiceTypes(state, data) {
      state.serviceTypes = data
    },
    setCurrentVehicle(state, data) {
      state.currentVehicle = data
    },
    setFuelLogs(state, data) {
      state.fuelLogs = data
    },
    setServiceLogs(state, data) {
      state.serviceLogs = data
    },
    addVehicle(state, data) {
      state.vehicles.push(data)
    },
    delVehicle(state, data) {
      const index = state.vehicles.findIndex((vehicle) => vehicle.id === data.id)
      if (index !== -1) {
        state.vehicles.splice(index, 1)
      }
    },
    patchVehicle(state, data) {
      const index = state.vehicles.findIndex((vehicle) => vehicle.id === data.id)
      if (index !== -1) {
        Object.assign(state.vehicles[index], data)
      }
    }
  },

  actions: {
    async fetchServiceTypes({ commit }) {
      try {
        var data = (await axios(process.env.VUE_APP_API_URL + 'v1/services')).data
        commit('setServiceTypes', data)
      } catch (error) {
        console.log(error)
      }
    },
    async fetchVehicles({ commit }) {
      try {
        commit('setApiState', API.LOADING)
        var data = (await axios(process.env.VUE_APP_API_URL + 'v1/vehicles')).data
        if (data.length > 0 && !localStorage.currentVehicle) {
          commit('setCurrentVehicle', data[0].name)
        }
        commit('setVehicles', data)
        commit('setApiState', API.LOADED)
      } catch (error) {
        console.log(error)
        commit('setApiState', API.ERROR)
      }
    },
    async addVehicle({ commit, dispatch }, data) {
      try {
        commit('setApiState', API.LOADING)
        const response = await axios.post(process.env.VUE_APP_API_URL + 'admin/v1/vehicle', data, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        if (response.status === 200) {
          dispatch('setSnackbar', { icon: 'mdi-check', text: `Success: ${response.data.name} added!`, color: 'success darken-2' }, { root: true })
          commit('addVehicle', response.data)
        }
        commit('setApiState', API.LOADED)
      } catch (error) {
        console.log(error)
        dispatch('setSnackbar', { icon: 'mdi-alert', text: `Something went wrong adding this vehicle.`, color: 'error darken-2' }, { root: true })
        commit('setApiState', API.ERROR)
      }
    },
    async delVehicle({ commit, dispatch }, data) {
      try {
        commit('setApiState', API.LOADING)
        const response = await axios.delete(process.env.VUE_APP_API_URL + `admin/v1/vehicle/${data.id}`)
        if (response.status === 200) {
          dispatch('setSnackbar', { icon: 'mdi-trash-can', text: `Success: ${data.name} deleted!`, color: 'red darken-2' }, { root: true })
          commit('delVehicle', data)
        }
        commit('setApiState', API.LOADED)
      } catch (error) {
        console.log(error)
        dispatch('setSnackbar', { icon: 'mdi-alert', text: `Something went wrong removing this vehicle.`, color: 'red darken-2' }, { root: true })
        commit('setApiState', API.ERROR)
      }
    },
    async patchVehicle({ commit, dispatch }, data) {
      try {
        commit('setApiState', API.LOADING)
        const response = await axios.post(process.env.VUE_APP_API_URL + `admin/v1/vehicle/${data.get('id')}`, data)
        if (response.status === 200) {
          dispatch('setSnackbar', { icon: 'mdi-check', text: `Success: ${response.data.name} updated!`, color: 'green darken-2' }, { root: true })
          commit('patchVehicle', response.data)
        }
        commit('setApiState', API.LOADED)
      } catch (error) {
        console.log(error)
        dispatch('setSnackbar', { icon: 'mdi-alert', text: `Something went wrong updating this vehicle.`, color: 'red darken-2' }, { root: true })
        commit('setApiState', API.ERROR)
      }
    },
    updateCurrentVehicle({ commit }, data) {
      commit('setCurrentVehicle', data)
    },
    async updateFuelLog({ commit, dispatch }, data) {
      try {
        commit('setApiState', API.LOADING)
        await axios.post(process.env.VUE_APP_API_URL + 'v1/vehicles/fuel/add', data)
        dispatch('fetchVehicles')
        dispatch('fetchFuelLogs')
        commit('setApiState', API.LOADED)
      } catch (error) {
        console.log(error)
        commit('setApiState', API.ERROR)
      }
    },
    async updateServiceLog({ commit, dispatch }, data) {
      try {
        commit('setApiState', API.LOADING)
        await axios.post(process.env.VUE_APP_API_URL + 'v1/vehicles/service/add', data)
        dispatch('fetchVehicles')
        dispatch('fetchServiceLogs')
        commit('setApiState', API.LOADED)
      } catch (error) {
        console.log(error)
        commit('setApiState', API.ERROR)
      }
    },
    async fetchFuelLogs({ commit }) {
      try {
        commit('setApiState', API.LOADING)
        var data = (await axios.get(process.env.VUE_APP_API_URL + 'v1/vehicles/fuel/log')).data
        commit('setFuelLogs', data)
        commit('setApiState', API.LOADED)
      } catch (error) {
        console.log(error)
        commit('setApiState', API.ERROR)
      }
    },
    async fetchServiceLogs({ commit }) {
      try {
        commit('setApiState', API.LOADING)
        var data = (await axios.get(process.env.VUE_APP_API_URL + 'v1/vehicles/service/log')).data
        commit('setServiceLogs', data)
        commit('setApiState', API.LOADED)
      } catch (error) {
        console.log(error)
        commit('setApiState', API.ERROR)
      }
    }
  }
}
