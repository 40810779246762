<template>
  <v-app>
    <v-app-bar app color="red darker-5" dark dense clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app clipped fixed>
      <template v-slot:prepend v-if="!user">
        <div class="pa-2">
          <v-img src="/img/bear_march.png" contain class="pb-12"></v-img>
          <v-btn block color="primary" to="/login"> Login </v-btn>
        </div>
      </template>
      <v-menu v-if="user" v-model="userMenu" close-on-content-click transition="slide-y-transition" offset-y bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-list>
            <v-list-item two-line v-bind="attrs" v-on="on">
              <v-list-item-avatar class="red darken-2">
                <span class="white--text text-h6"> {{ getInitials(user.name) }} </span>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="text-capitalize"> {{ user.name }} </v-list-item-title>
                <v-list-item-subtitle class="text-capitalize"> {{ user.role }} </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon v-if="!userMenu" large>mdi-menu-down</v-icon>
                <v-icon v-if="userMenu" large>mdi-menu-up</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </template>
        <v-list>
          <v-list-item v-if="user && user.role === 'super'" link :to="{ name: 'SuperAdminIndex' }">
            <v-list-item-content class="text-button">Super</v-list-item-content>
          </v-list-item>
          <v-list-item v-if="user && (user.role === 'admin' || user.role === 'super')" link :to="{ name: 'AdminIndex' }">
            <v-list-item-content class="text-button">Admin</v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'ProfileIndex' }">
            <v-list-item-content class="text-button">Profile</v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-content class="red--text text-button" @click="logout">logout</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-list v-if="user">
        <v-list-item-group color="red darken-2">
          <div v-for="(link, i) in privateLinks" :key="i">
            <v-list-item :to="{ name: link.route }" link exact>
              <v-list-item-content>
                <v-list-item-title>
                  {{ link.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>

      <v-divider v-if="user"></v-divider>

      <v-list>
        <v-list-item-group color="red darken-2">
          <div v-for="(link, i) in publicLinks" :key="i">
            <v-list-item :to="{ name: link.route }" link exact>
              <v-list-item-content>
                <v-list-item-title>
                  {{ link.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-footer app color="grey darken-4">
      <div class="text-right grey--text">&copy;{{ new Date().getFullYear() }} Ryan Capehart</div>
    </v-footer>

    <v-snackbar
      v-for="(snackbar, index) in snackbars"
      :key="`snackbar_${index}_${Math.random()}`"
      v-model="snackbar.showing"
      :timeout="snackbar.timeout"
      light
      top
      multi-line
      :style="`top: ${index * 70 + 10}px`"
    >
      <span class="d-flex align-center">
        <v-icon :color="snackbar.color" class="mr-2">
          {{ snackbar.icon }}
        </v-icon>
        {{ snackbar.text ? snackbar.text : 'aba|tools' }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn color="atBlue" text v-bind="attrs" @click="snackbar.showing = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import stringHelpers from '../shared/mixins/stringHelpers.js'

export default {
  name: 'DefaultLayout',
  components: {},
  mixins: [stringHelpers],
  data() {
    return {
      drawer: false,
      userMenu: false,
      privateLinks: [
        {
          route: 'VehicleTrackerList',
          color: 'red',
          title: 'Vehicles'
        }
      ],
      publicLinks: [
        {
          route: 'Home',
          color: 'red',
          title: 'Home'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters(['snackbars'])
  },
  created() {
    if (localStorage.getItem('authToken')) {
      this.getUserData()
    }
  },
  methods: {
    ...mapActions('auth', ['sendLogoutRequest', 'getUserData']),
    logout() {
      this.sendLogoutRequest()
      this.$router.push('/')
    }
  }
}
</script>

<style></style>
