<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer app color="grey darken-4">
      <div class="text-right grey--text">&copy;{{ new Date().getFullYear() }} Ryan Capehart</div>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'LoginLayout'
}
</script>

<style></style>
