import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import vehicle from '@/store/modules/vehicle'
import groups from './modules/group'
import stickers from './modules/stickers'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    errors: {},
    snackbars: []
  },

  getters: {
    errors: (state) => state.errors,
    snackbars: (state) => state.snackbars.filter((s) => s.showing).slice(-1)
  },

  mutations: {
    setErrors(state, errors) {
      state.errors = errors
    },
    setSnackbar(state, snackbar) {
      state.snackbars.splice(0, 1, snackbar)
    }
  },

  actions: {
    setSnackbar({ commit }, snackbar) {
      snackbar.showing = true
      snackbar.color = snackbar.color || 'success'
      snackbar.icon = snackbar.icon || 'mdi-alert'
      commit('setSnackbar', snackbar)
    }
  },

  modules: {
    auth,
    groups,
    stickers,
    vehicle
  }
})
