import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
// import VueAxios from 'vue-axios'

Vue.config.productionTip = false
// Vue.use(VueAxios, axios)

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 422) {
      store.commit('setErrors', error.response.data.errors)
    } else if (error.response.status === 401) {
      store.commit('auth/setUserData', null)
      localStorage.removeItem('authToken')
      router.push({ name: 'Login' })
    } else {
      return Promise.reject(error)
    }
  }
)

axios.interceptors.request.use(function (config) {
  config.headers.common = {
    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }

  return config
})

Vue.component('default-layout', require('./layouts/Default.vue').default)
Vue.component('login-layout', require('./layouts/Login.vue').default)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  created() {
    document.title = 'ryancapehart.com'
  }
}).$mount('#app')
