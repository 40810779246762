import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const guest = (to, from, next) => {
  if (!localStorage.getItem('authToken')) {
    return next()
  } else {
    return next('/')
  }
}
const auth = (to, from, next) => {
  if (localStorage.getItem('authToken')) {
    return next()
  } else {
    return next('/login')
  }
}

const admin = (to, from, next) => {
  if (localStorage.getItem('authToken')) {
    if (JSON.parse(localStorage.getItem('userData')).role === 'admin' || JSON.parse(localStorage.getItem('userData')).role === 'super') {
      return next()
    } else {
      return next('/')
    }
  } else {
    return next('/login')
  }
}

const superAdmin = (to, from, next) => {
  if (localStorage.getItem('authToken')) {
    if (JSON.parse(localStorage.getItem('userData')).role === 'super') {
      return next()
    } else {
      return next('/')
    }
  } else {
    return next('/login')
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: { layout: 'default' }
  },
  {
    path: '/banana',
    name: 'BananaStickers',
    component: () => import(/* webpackChunkName: "bananaSticker" */ '../views/bananaSticker/Index.vue'),
    meta: { layout: 'default' }
  },
  {
    path: '/calculator',
    name: 'CalculatorParent',
    component: () => import(/* webpackChunkName: "calculatorParent" */ '../views/calculators/Index.vue'),
    children: [
      {
        path: 'list',
        name: 'CalculatorList',
        beforeEnter: auth,
        component: () => import(/* webpackChunkName: "calculatorList" */ '../views/calculators/List.vue')
      },
      {
        path: 'snowball',
        name: 'CalculatorSnowball',
        beforeEnter: auth,
        component: () => import(/* webpackChunkName: "calculatorSnowball" */ '../views/calculators/Snowball.vue')
      }
    ]
  },
  {
    path: '/tracker',
    name: 'VehicleTrackerHome',
    beforeEnter: auth,
    component: () => import(/* webpackChunkName: "trackerHome" */ '../views/vehicleTracker/Index.vue'),
    children: [
      {
        path: 'list',
        name: 'VehicleTrackerList',
        beforeEnter: auth,
        component: () => import(/* webpackChunkName: "vehicleTrackerList" */ '../views/vehicleTracker/List.vue')
      },
      {
        path: 'log',
        name: 'VehicleTrackerLog',
        beforeEnter: auth,
        component: () => import(/* webpackChunkName: "vehicleTrackerLog" */ '../views/vehicleTracker/Log.vue')
      },
      {
        path: 'add-record',
        name: 'VehicleTrackerAddRecord',
        beforeEnter: auth,
        component: () => import(/* webpackChunkName: "vehicleTrackerAddRecord" */ '../views/vehicleTracker/AddRecord.vue')
      },
      {
        path: 'vehicle/:vehicle',
        beforeEnter: auth,
        name: 'TrackerVehicleSummary',
        component: () => import(/* webpackChunkName: "vehicleTrackerSummary" */ '../views/vehicleTracker/Summary.vue')
      }
    ]
    // meta: { layout: 'default' }
  },
  {
    path: '/login',
    beforeEnter: guest,
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Login.vue'),
    meta: { layout: 'login' }
  },
  {
    path: '/verify/:hash',
    name: 'Verify',
    beforeEnter: auth,
    props: true,
    component: () => import(/* webpackChunkName: "verify" */ '../views/auth/Verify.vue')
  },
  {
    path: '/profile',
    name: 'ProfileIndex',
    beforeEnter: auth,
    component: () => import(/* webpackChunkName: "profileIndex" */ '../views/profile/Index.vue')
  },
  {
    path: '/admin',
    name: 'AdminIndex',
    beforeEnter: admin,
    component: () => import(/* webpackChunkName: "adminIndex" */ '../views/admin/Index.vue'),
    children: [
      {
        path: 'manage-vehicles',
        name: 'AdminManageVehicles',
        beforeEnter: admin,
        component: () => import(/* webpackChunkName: "adminManageVehicles" */ '../views/admin/vehicles/ManageVehicles.vue')
      }
    ]
  },
  {
    path: '/super-admin',
    name: 'SuperAdminIndex',
    beforeEnter: superAdmin,
    component: () => import(/* webpackChunkName: "superAdminIndex" */ '../views/superAdmin/Index.vue'),
    children: [
      {
        path: 'group-manager',
        name: 'SuperAdminGroupManager',
        beforeEnter: superAdmin,
        component: () => import(/* webpackChunkName: "superAdminGroupManager" */ '../views/superAdmin/groups/Index.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
